import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { debounce } from "lodash";
import { db } from "./../firebase/firebase-config";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";
import { createStar } from "./components/starConfig";

const Universe = () => {
  const ref = useRef();
  const [starsData, setStarsData] = useState([]);
  const [selectedStar, setSelectedStar] = useState(null);

  useEffect(() => {
    const fetchStars = async () => {
      const storage = getStorage();
      const querySnapshot = await getDocs(
        query(collection(db, "stars"), orderBy("date")),
      );
      const stars = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const data = doc.data();
          let imageUrl = data.url;
          if (imageUrl.startsWith("gs://")) {
            const imageRef = storageRef(storage, imageUrl);
            imageUrl = await getDownloadURL(imageRef).catch(() => "");
          }
          return {
            id: doc.id,
            ...data,
            url: imageUrl,
          };
        }),
      );
      setStarsData(stars);
    };

    fetchStars();
  }, []);

  useEffect(() => {
    const svg = d3
      .select(ref.current)
      .attr("width", window.innerWidth)
      .attr("height", window.innerHeight)
      .style("border", "1px solid black");

    let lastTime = Date.now();
    const starAdditionDelay = 1000;
    let dataIndex = 0;

    const getRandomCoordinates = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const centerX = width / 2;
      const centerY = height / 2;
      const maxRadius = 0.25 * Math.min(width, height);

      const angle = Math.random() * 2 * Math.PI;
      const radius = Math.random() * maxRadius;

      const x = centerX + radius * Math.cos(angle);
      const y = centerY + radius * Math.sin(angle);

      return { x, y };
    };

    const animate = () => {
      const now = Date.now();
      if (now - lastTime > starAdditionDelay && dataIndex < starsData.length) {
        const starData = starsData[dataIndex];
        const { x, y } = getRandomCoordinates();
        createStar(svg, starData, x, y, setSelectedStar);
        dataIndex++;
        lastTime = now;
      }
      requestAnimationFrame(animate);
    };

    requestAnimationFrame(animate);

    const handleResize = debounce(() => {
      svg.attr("width", window.innerWidth).attr("height", window.innerHeight);
    }, 100);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [starsData]);

  useEffect(() => {
    let animationFrameId;

    const updateTooltipPosition = () => {
      if (selectedStar) {
        const tooltip = document.querySelector(".tooltip");
        if (tooltip) {
          tooltip.style.left = `${selectedStar.star.x + 20}px`;
          tooltip.style.top = `${selectedStar.star.y + 20}px`;
        }
      }
      animationFrameId = requestAnimationFrame(updateTooltipPosition);
    };

    animationFrameId = requestAnimationFrame(updateTooltipPosition);

    return () => cancelAnimationFrame(animationFrameId);
  }, [selectedStar]);

  return (
    <>
      <svg ref={ref}></svg>
      {selectedStar && (
        <div
          className="tooltip rounded-lg w-[300px] "
          style={{
            left: `${selectedStar.star.x + 20}px`,
            top: `${selectedStar.star.y + 20}px`,
          }}
        >
          <button
            className="mr-2"
            onClick={() => setSelectedStar(null)}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              border: "none",
              background: "transparent",
              cursor: "pointer",
              fontSize: "24px",
            }}
          >
            &#10005; {/* Unicode multiplication sign (looks like an 'X') */}
          </button>
          <h2 className="text-lg">{selectedStar.starData.event}</h2>
          <p className="text-sm">{selectedStar.starData.description}</p>
          {selectedStar.starData.url && (
            <img
              className="rounded mt-2"
              src={selectedStar.starData.url}
              alt={selectedStar.starData.description}
            />
          )}
        </div>
      )}
      <style jsx>{`
        .tooltip {
          position: absolute;
          background: white;
          border: 1px solid #ccc;
          padding: 10px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          z-index: 1000;
          pointer-events: auto; // Change to auto to allow interactions
        }
      `}</style>
    </>
  );
};

export default Universe;
