import React, { useState, useEffect } from "react";
import { auth } from "./firebase/firebase-config";
import AuthComponent from "./pages/AuthComponent";
import Universe from "./pages/Universe";
import "./tailwind.css";

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div className="loading text-white">Loading...</div>;
  }

  return (
    <div>
      <main>
        {user ? (
          <section className="universe">
            <Universe />
          </section>
        ) : (
          <section className="login">
            <AuthComponent />
          </section>
        )}
      </main>
    </div>
  );
}

export default App;
