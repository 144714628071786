import React, { useEffect, useState } from "react";
import { signIn } from "./../firebase/authFunctions"; // Make sure the path is correct

function AuthComponent() {
  const [email, setEmail] = useState("contact@aleksandrmolchagin.com");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); // Optional: For displaying error messages

  useEffect(() => {
    generateStars();
  }, []);

  const generateStars = () => {
    const numStars = 200;
    const body = document.querySelector("body");

    for (let i = 0; i < numStars; i++) {
      const star = document.createElement("div");
      star.className = "star";
      star.style.top = `${Math.random() * 100}vh`;
      star.style.left = `${Math.random() * 100}vw`;
      star.style.setProperty(
        "--animation-duration",
        `${Math.random() * 5 + 2}s`,
      );
      body.appendChild(star);
    }
  };

  const handleSignIn = () => {
    signIn(email, password)
      .then(() => {
        console.log("Signed in successfully");
        //destroy stars
        const stars = document.querySelectorAll(".star");
        stars.forEach((star) => star.remove());
      })
      .catch((error) => {
        console.error("Sign-in error:", error.message);
        setError(error.message); // Display error message if needed
      });
  };

  return (
    <div className="auth-container w-full h-screen flex flex-col justify-center items-center">
      <img src="./logo512.png" className="-mb-8 z-10" alt="logo" />
      {/* <input
        className="w-[220px] p-2 rounded mb-2"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter email"
      /> */}
      <input
        className="w-[220px] p-2 rounded mb-4 z-10"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Enter password"
      />
      <button
        className="bg-[#9966cc] w-[100px] p-2 rounded text-white hover:bg-[#8844bb] focus:outline-none focus:ring-2 focus:ring-purple-300 active:bg-[#7722aa]  z-10"
        onClick={handleSignIn}
        aria-label="Unlock account"
        style={{ transition: "background-color 0.3s ease" }}
      >
        Unlock
      </button>

      {error && <p className="error text-[#c00000] mt-2">{error}</p>}
    </div>
  );
}

export default AuthComponent;
