import * as d3 from "d3";

export const starSettings = {
  default: { minSize: 1, maxSize: 2, color: "#ffffff" }, // White stars for a general starfield
  core: { minSize: 2, maxSize: 4, color: "#dc2e70" }, // Golden yellow for the core
  flowers: { minSize: 2, maxSize: 4, color: "#ff69b4" }, // Hot pink for flower-type stars
  gift: { minSize: 2, maxSize: 4, color: "#00ff00" }, // Bright green for gift-type stars
  text: { minSize: 1, maxSize: 2, color: "#1e90ff" }, // Dodger blue for text-type stars
  // nebula: { minSize: 3, maxSize: 5, color: "#8a2be2" }, // Blue-violet for nebulae
  // supernova: { minSize: 5, maxSize: 7, color: "#ff4500" }, // Orange-red for supernovae
};

export function createStar(svg, starData, centerX, centerY, setSelectedStar) {
  const settings = starSettings[starData.type] || starSettings.default;
  const minSize = settings.minSize;

  // Define the glow filter if it doesn't exist
  if (svg.select("defs").empty()) {
    svg
      .append("defs")
      .append("filter")
      .attr("id", "glow")
      .attr("x", "-50%")
      .attr("y", "-50%")
      .attr("width", "200%")
      .attr("height", "200%")
      .append("feGaussianBlur")
      .attr("stdDeviation", "3.5")
      .attr("result", "coloredBlur");

    svg
      .select("#glow")
      .append("feMerge")
      .selectAll("feMergeNode")
      .data(["coloredBlur", "SourceGraphic"])
      .enter()
      .append("feMergeNode")
      .attr("in", (d) => d);
  }

  let star = {
    angle: Math.random() * 2 * Math.PI,
    radius:
      Math.random() * (Math.min(centerX, centerY) - minSize * 2) + minSize,
    size: Math.random() * (settings.maxSize - minSize) + minSize,
    color: settings.color,
    x: centerX,
    y: centerY,
  };

  const updateStarPosition = () => {
    star.angle += 0.001;
    star.x = centerX + star.radius * Math.cos(star.angle);
    star.y = centerY + star.radius * Math.sin(star.angle);
  };

  const hitArea = svg
    .append("circle")
    .attr("cx", star.x)
    .attr("cy", star.y)
    .attr("r", star.size + 10) // Increase radius for the clickable area
    .attr("fill", "none") // Make the circle invisible
    .style("pointer-events", "all") // Ensure it can still be clicked
    .style("cursor", "pointer")
    .attr("data-id", starData.id) // Add data-id attribute
    .on("click", () => {
      setSelectedStar({ starData, star });
    });

  const starElement = svg
    .append("circle")
    .attr("cx", star.x)
    .attr("cy", star.y)
    .attr("r", star.size)
    .attr("fill", star.color)
    .attr("opacity", 0) // Start with opacity 0
    .attr("data-id", starData.id) // Add data-id attribute
    .style("cursor", "pointer")
    .style("filter", "url(#glow)") // Apply the glow filter
    .on("click", () => {
      setSelectedStar({ starData, star });
    });

  star.element = starElement;
  star.hitArea = hitArea;

  // Apply the transition after ensuring any ongoing transition is stopped
  star.element
    .interrupt()
    .transition()
    .duration(1000) // Duration of the transition in milliseconds
    .attr("opacity", 1); // End with opacity 1

  const trail = svg.append("g").attr("class", "trail");

  const addTrail = () => {
    trail
      .append("circle")
      .attr("cx", star.x)
      .attr("cy", star.y)
      .attr("r", star.size / 2) // Smaller size for the tail
      .attr("fill", star.color)
      .attr("opacity", 0.2) // Initial opacity of the tail
      .transition()
      .duration((1000 * star.size) / 2)
      .attr("opacity", 0)
      .remove(); // Remove the tail circle after fading out
  };

  const animateStar = () => {
    updateStarPosition();
    star.element.attr("cx", star.x).attr("cy", star.y);
    hitArea.attr("cx", star.x).attr("cy", star.y);
    addTrail(); // Add the tail effect
    requestAnimationFrame(animateStar);
  };

  requestAnimationFrame(animateStar);

  return star;
}
